import React, { useState } from "react";
import "./MyServicesStyles.css";
import ContactDetailEnum from "../../../data/enums/ContactDetailsEnum";
import { ReactComponent as InstagramSVG } from '../../../assets/instagram.svg';
import { ReactComponent as WhatsappSVG } from '../../../assets/whatsapp.svg';
import { ReactComponent as TelegramSVG } from '../../../assets/telegram.svg';
import ReactGA from 'react-ga4';
import YandexMetrikaEnums from "../../../data/enums/YandexMetrikaEnum";
import ScrollAnimation from "../../ScrollAnimation";
import encodingServices from "../../../services/encodingServices";

interface Service {
    id: number;
    name: string;
    description: string;
    price: number;
}

interface ServiceSection {
    title: string;
    services: Service[];
}

const serviceSections: ServiceSection[] = [
    {
        title: "До прилета",
        services: [
            { id: 1, name: "Консультация по документам для родов", description: "Проконсультирую про документы необходимые для родов в Аргентине", price: 30 },
            { id: 2, name: "Консультация по документам после родов", description: "Консультация по документам, необходимым для легализации родителей и других членов семьи в Аргентине (DNI)", price: 30 },
            { id: 3, name: "Консультация по документам для гражданства", description: "Консультация по документам для гражданства Аргентины для родителей и других членов семьи в Аргентине", price: 30 },
            // { id: 4, name: "Консультация по сбору чемодана в Аргентине", description: "Расскажу, что лучше привезти с собой, а что можно купить на месте", price: 0 },
            { id: 5, name: "Консультация по подбору жилья", description: "Помощь в подборе жилья на первое время в Буэнос-Айресе", price: 30 },
        ],
    },
    {
        title: "Перелет",
        services: [
            // { id: 6, name: "Помощь с подбором авиабилетов в Аргентину", description: "Помогу подобрать билеты на самолет", price: 0 },
            // { id: 7, name: "Консультация по справке для перелета", description: "Проконсультирую про справку для перелета", price: 0 },
            { id: 8, name: "Консультация по перелету с животными", description: "Проконсультирую про необходимые документы для ввоза животных в Аргентину, перелет, и т.п", price: 30 },
            { id: 9, name: "Встреча в аэропорту с водителем", description: "Встречу Вас в аэропорту с водителем на комфортной машине", price: 100 },
        ],
    },
    {
        title: "Пребывание в Аргентине",
        services: [
            { id: 10, name: "Помощь в оформлении сим-карты", description: "Помогу приобрести и активировать сим-карту местного оператора", price: 25 },
            // { id: 11, name: "Рекомендация проверенных обменников", description: "Подскажу, где безопаснее и выгоднее обменять деньги, криптовалюту", price: 0 },
            { id: 12, name: "Помощь в заключении договора на аренду жилья в Аргентине", description: "Помогу заключить договор на аренду жилья", price: 30 },
            { id: 13, name: "Помощь в оформлении заказов через Mercado Libre", description: "Помогу офоримить заказ в местном онлайн маркетплейсе Mercado Libre", price: 20 },
            { id: 14, name: "Помощь в оформлении турно к врачам и на анализы", description: "Запишу Вас к необходимому специалисту", price: 15 },
            { id: 15, name: "Сопровождение к любым врачам по вопросам здоровья", description: "Буду сопровождать Вас на приеме у специалиста", price: 25 },
            { id: 16, name: "Сопровождение на прививки для малыша", description: "Буду сопровождать Вас на прививки для малыша", price: 25 },
            { id: 17, name: "Сопровождение по вопросам, не касающимся медицины и здоровья", description: "Сопровождение для вет.клиник, монотрибуто, школьные собрания и т.п.", price: 25 },
            { id: 38, name: "Помощь в оформлении абонемента в спортзал", description: "Помогу оформить абонемент в местный спортзал", price: 30 },
            { id: 37, name: "Помощь в оформлении медицинской страховки", description: "Сопровождение для оформления местной медицинской страховки", price: 100 },
            { id: 18, name: "Помощь в оформлении водительского удостоверения", description: "Помогу оформить ВУ Аргентины на те документы, которые у вас на руках", price: 100 },

        ],
    },
    {
        title: "Роды",
        services: [
            { id: 19, name: "Знакомства с врачами и подбор врача", description: "Предложу несколько вариантов врачей и сопровожу на первый прием", price: 50 },
            { id: 20, name: "Сопровождение к врачам во время беременности", description: "Сопровождение к специалистам на приемы во время беременности", price: 25 },
            { id: 21, name: "Организация экскурсии в клиники", description: "Просмотр клиник, которые Вас заинтересуюти", price: 30 },
            { id: 22, name: "Организация курсов подготовки к родам", description: "Курсы подготовки к родам с практикующей акушеркой с сопровождением", price: 50 },
            // { id: 23, name: "Помощь в сборе сумки в роддом", description: "Консультация по тому, что брать с собой в роддом", price: 0 },
            { id: 24, name: "Сопровождение на узи до родов", description: "Сопровождение к специалисту на узи до родов", price: 50 },
            { id: 25, name: "Сопровождение на анализы до родов", description: "Сопровождение в лабораторию анализов до родов", price: 25 },
            { id: 26, name: "Сопровождение в банк стволовых клеток", description: "Сопровождение в банк стволовых клеток и помощь в подписании договора", price: 30 },
            { id: 27, name: "Организация выписки из роддома", description: "В день выписки встречу Вас с цветами и сопровождение при выписке из роддома", price: 150 },
            { id: 28, name: "Организация выписки из роддома и транспорт", description: "В день выписки встречу Вас с цветами, сопровождение при выписке из роддома, и организация машины из госпиталя", price: 200 },
        ],
    },
    {
        title: "Документы",
        services: [
            { id: 29, name: "Помощь в оформлении DNI на ребенка", description: "Сопровождение в местную организацию для оформления DNI на ребенка - обычный срок", price: 25 },
            { id: 30, name: "Помощь в оформлении DNI на ребенка - срочно", description: "Сопровождение в местную организацию для оформления DNI на ребенка - в срочном порядке", price: 50 },
            { id: 31, name: "Помощь в оформлении загранпаспорта для ребенка", description: "Сопровождение в местную организацию для оформления загранпаспорта на ребенка - обычный срок", price: 30 },
            { id: 32, name: "Помощь в оформлении загранпаспорта для ребенка - срочно", description: "Сопровождение в местную организацию для оформления загранпаспорта на ребенка - в срочном порядке", price: 100 },
            { id: 33, name: "Помощь в разрешении на вывоз ребенка одним из родителей", description: "Сопровождение в местную организацию для оформления разрешения на вывоз ребенка одним из родителей", price: 50 },
            { id: 34, name: "Апостилирование свидетельства о рождении", description: "Сопровождение в местную организацию для оформления апостиля на свидетельство о рождении", price: 150 },
            { id: 35, name: "Помощь с электронным СОР с апостилем", description: "Сопровождение в местную организацию для оформления электронного свидетельства о рождении и апостиля", price: 150 },
            { id: 36, name: "Помощь с оформлением монотрибуто", description: "Сопровождение в местную налоговую организацию для оформления монотрибуто ", price: 50 },
        ]

    }

];

const MyServices: React.FC = () => {
    const [expandedServices, setExpandedServices] = useState<{ [key: number]: boolean }>({}); // Track expanded states by service ID

    const toggleServiceExpansion = (serviceId: number) => {
        setExpandedServices((prevState) => ({
            ...prevState,
            [serviceId]: !prevState[serviceId], // Toggle the expanded state for the service
        }));
    };

    return (
        <div className="my-services-container">
            {serviceSections.map((section) => (
                <div key={section.title} className="my-services-section">
                    <div className="my-services-section-title-container">
                        <h2 className="my-services-section-title">{section.title}</h2>
                    </div>
                    <ul className="my-services-service-list">
                        {section.services.map((service) => (
                            <ScrollAnimation direction={'down'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                                <li key={service.id} className="my-services-service-item">
                                    <h4 className="my-services-service-name">{service.name}</h4>
                                    <p className="my-services-service-description">{service.description}</p>
                                    {!expandedServices[service.id] && (
                                        <div
                                            //onClick={() => toggleServiceExpansion(service.id)}
                                            className="price-value-button price-with-pulse-animation standardToggleDetails my-services-price-button"
                                        >
                                            Недоступно
                                        </div>
                                    )}
                                    {expandedServices[service.id] && (
                                        <div>
                                            <div
                                                onClick={() => toggleServiceExpansion(service.id)} // Toggle to collapse
                                                className="price-value my-services-price-value"
                                            >
                                                {service.price}$
                                            </div>
                                            <div className='package-section-contact-me-social-media-container my-services-contact-me-social-media-container'>
                                                <a href={ContactDetailEnum.Instagram} target="_blank" rel="noopener noreferrer">
                                                    <InstagramSVG className="package-section-contact-me-social-icon package-social-button-with-pulse-animation
                                                my-services-social-icon"
                                                        onClick={() => {
                                                            ReactGA.event({
                                                                category: 'User',
                                                                action: `ig -> услуга ${service.name}`
                                                            });
                                                            ym(YandexMetrikaEnums.id, 'reachGoal', 'Instagram-service');
                                                        }} />
                                                </a>
                                                <a href={`${ContactDetailEnum.WhatsAppCompoundHttpsAndNumber}${encodingServices.encodeToURL(`Hola! Хочу узнать про ${service.name} - ${service.price}$`)}`} target="_blank" rel="noopener noreferrer"
                                                    onClick={() => {
                                                        ReactGA.event({
                                                            category: 'User',
                                                            action: `wa -> услуга ${service.name}`
                                                        });
                                                        ym(YandexMetrikaEnums.id, 'reachGoal', 'WhatsApp-service');
                                                    }}>
                                                    <WhatsappSVG className="package-section-contact-me-social-icon package-social-button-with-pulse-animation
                                                my-services-social-icon" />
                                                </a>
                                                <a href={ContactDetailEnum.Telegram} target="_blank" rel="noopener noreferrer"
                                                    onClick={() => {
                                                        ReactGA.event({
                                                            category: 'User',
                                                            action: `tg -> услуга ${service.name}`
                                                        });
                                                        ym(YandexMetrikaEnums.id, 'reachGoal', 'Telegram-service');
                                                    }}>
                                                    <TelegramSVG className="package-section-contact-me-social-icon package-social-button-with-pulse-animation
                                                my-services-social-icon" />
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ScrollAnimation>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default MyServices;
