import React, { useEffect, useState } from 'react';
import OurPackages from '../landingPageSections/packagesSection/OurPackages';
import useTitleAndOgTitleMetaTag from '../UseTitleAndOgTitleMetaTag';
import SiteMetaPropertyEnum from '../../data/enums/SiteMetaPropertiesEnum';
import UseDescriptionAndOgDescriptionMetaTag from '../UseDescriptionAndOgDescriptionMetaTag';
import UseOgUrlMetaTag from '../UseOgUrlMetaTag';
import UseOgTypeMetaTag from '../UseOgTypeMetaTag';
import UseCanonicalLinkTag from '../UseCanonicalLinkTag';
import MyServices from '../landingPageSections/myServicesSection/MyServices';
import { Link } from 'react-router-dom';


const MyServicesPage: React.FC = () => {

    useTitleAndOgTitleMetaTag(SiteMetaPropertyEnum.MyServicesPageSiteTitle, SiteMetaPropertyEnum.MyServicesPageSiteTitle);

    UseDescriptionAndOgDescriptionMetaTag(SiteMetaPropertyEnum.MyServicesPageSiteDescription, SiteMetaPropertyEnum.MyServicesPageSiteDescription);

    UseOgUrlMetaTag(SiteMetaPropertyEnum.URLMyServicesSite);
    UseOgTypeMetaTag(SiteMetaPropertyEnum.OgTypeWebsite);
    UseCanonicalLinkTag(SiteMetaPropertyEnum.URLMyServicesSite)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='our-packages-page-container'>
            <p className='our-packages-page-section-text'>Ниже указаны разовые сопровождения, которые я оказываю, чтобы помочь в Ваших вопросах в Аргентине.</p>
            <p><strong>Разовые сопровождения временно недоступны из-за высокого спроса.</strong></p>
            <p className='our-packages-page-section-text'>Я также могу помочь Вам <Link to="/birth/">организовать роды Вашей мечты</Link> в рамках всестороннего сопровождения.</p>
            <h1 className="packageSectionHeading">Мои Услуги</h1>
            <MyServices />
        </div>
    );
};

export default MyServicesPage;
